import React, { Children } from 'react';
import { motion } from 'framer-motion';

const LandingPage: React.FC = () => {
  return (
    <div className="min-h-screen flex flex-col justify-between bg-gradient-to-b from-bhmc-blue-50 to-white">
      <header className="py-2"> {/* Reduced padding */}
        <motion.div
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          className="container mx-auto px-4"
        >
          <img src="/logo.png" alt="BHMC Logo" className="mx-auto h-32 md:h-40" /> {/* Reduced height */}
        </motion.div>
      </header>

      <main className="flex-grow flex items-center py-4"> {/* Reduced padding */}
        <div className="container mx-auto px-4">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 0.5 }}
            className="max-w-3xl mx-auto text-center"
          >
            <h1 className="text-4xl md:text-5xl font-extrabold text-bhmc-blue-600 mb-3">
              Innovating Beauty & Health
            </h1>
            <p className="text-lg md:text-xl text-gray-600 mb-8">
              Our new website is under construction. We're working hard to bring you cutting-edge beauty and health solutions that will revolutionize your well-being.
            </p>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 1, delay: 1 }}
              className="bg-white bg-opacity-50 backdrop-filter backdrop-blur-lg p-6 rounded-lg shadow-lg"
            >
              <h2 className="text-xl font-bold text-bhmc-blue-800 mb-3">Stay Connected</h2>
              <p className="text-gray-600 mb-4">Follow us on our social media accounts to stay updated and learn more:</p>
              <div className="flex justify-center space-x-6">
                <a href="https://facebook.com" target="_blank" rel="noopener noreferrer" className="text-bhmc-blue-600 hover:text-bhmc-blue-800 transition duration-300">
                  <i className="fab fa-facebook fa-2x"></i>
                </a>
                <a href="https://twitter.com" target="_blank" rel="noopener noreferrer" className="text-bhmc-blue-600 hover:text-bhmc-blue-800 transition duration-300">
                  <i className="fab fa-twitter fa-2x"></i>
                </a>
                <a href="https://instagram.com" target="_blank" rel="noopener noreferrer" className="text-bhmc-blue-600 hover:text-bhmc-blue-800 transition duration-300">
                  <i className="fab fa-instagram fa-2x"></i>
                </a>
                <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer" className="text-bhmc-blue-600 hover:text-bhmc-blue-800 transition duration-300">
                  <i className="fab fa-linkedin fa-2x"></i>
                </a>
              </div>
            </motion.div>
          </motion.div>
        </div>
      </main>

      <footer className="py-4 bg-bhmc-blue-800 text-white">
        <div className="container mx-auto px-4">
          <div className="flex flex-col md:flex-row justify-between items-center">
            <p className="text-sm mb-2 md:mb-0">&copy; 2024 BHMC. All rights reserved.</p>
            <div className="flex space-x-4">
              <a href="#" className="hover:text-bhmc-blue-200 transition duration-300">Privacy Policy</a>
              <a href="#" className="hover:text-bhmc-blue-200 transition duration-300">Terms of Service</a>
              <a href="#" className="hover:text-bhmc-blue-200 transition duration-300">Contact Us</a>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default LandingPage;
